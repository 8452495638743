import request from '@/utils/request'


// 查询用户ID靓号列表
export function listUser(query) {
  return request({
    url: '/biz/beautifulAccount/list',
    method: 'get',
    params: query
  })
}

// 查询用户ID靓号分页
export function pageUser(query) {
  return request({
    url: '/biz/beautifulAccount/page',
    method: 'get',
    params: query
  })
}

// 查询用户ID靓号详细
export function getUser(data) {
  return request({
    url: '/biz/beautifulAccount/detail',
    method: 'get',
    params: data
  })
}

// 新增用户ID靓号
export function addUser(data) {
  return request({
    url: '/biz/beautifulAccount/add',
    method: 'post',
    data: data
  })
}

// 修改用户ID靓号
export function updateUser(data) {
  return request({
    url: '/biz/beautifulAccount/edit',
    method: 'post',
    data: data
  })
}
// 生成靓号
export function createBeautifulAccount(data) {
  return request({
    url: '/biz/beautifulAccount/createBeautifulAccount',
    method: 'post',
    data: data
  })
}

// 删除用户ID靓号
export function delUser(data) {
  return request({
    url: '/biz/beautifulAccount/delete',
    method: 'post',
    data: data
  })
}
